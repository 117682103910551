import React from "react";
import "./completion.css";
import { Helmet } from "react-helmet";

const DonationSuccess = () => {
  return (
    <>
      <Helmet>
        <title> Thank you for your donation| Hestias Foundation</title>
        <meta
          name="description"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
        <meta
          name="keywords"
          content=" Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse quis molestiae accusamus vel, totam cumque ad deleniti optio voluptatibus delectus incidunt? Ad, voluptate. Atque quia nam deleniti distinctio possimus."
        />
      </Helmet>
      <div className="completion pt-6">
        <div className="container">
          <h3>Thank you for your generous donation! 🎉</h3>
          <p>
            Your support makes a significant difference, and we’re incredibly
            grateful for your contribution. <br /> Together, we’re
            making an impact.
          </p>
        </div>
      </div>
    </>
  );
};

export default DonationSuccess;
