import React, { lazy, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./coming-events.css";
import { upcomingEvents as upcomingEventsData } from "../../constants/data";
import { Link } from "react-router-dom";
import { FaCalendar, FaMapMarker, FaClock } from "react-icons/fa";

import ComingEventMetadata from "../../components/generateMetadata/ComingEventMetadata";

const UpcomingEvents = lazy(() => import("../../components/upcoming-events"));

function convertToSlug(text) {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
}

const ComingEvents = () => {
  const { upcomingEventSlug } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [comingEvent, setComingEvent] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const foundEvent = upcomingEventsData.find(
        (event) => convertToSlug(event.title) === upcomingEventSlug
      );
      if (foundEvent) {
        setComingEvent(foundEvent);
      } else {
        // If no event is found, navigate to a 404 page or show an error
        navigate("/not-found", { replace: true });
      }
      setIsLoading(false);
    };

    fetchEvent();
  }, [upcomingEventSlug, navigate]);

  if (isLoading) {
    return <div>Loading...</div>; // replace this with loading skeleton
  }

  if (!comingEvent) {
    return null; // This will be handled by the navigation to 404 page
  }

  return (
    <>
      <ComingEventMetadata event={comingEvent} />
      <section
        className="upcomingDetailHeader"
        style={{
          backgroundImage: comingEvent.image
            ? `url(${comingEvent.image})`
            : "none",
        }}
      >
        <div className="blurBkg">
          <div className="container">
            <div className="eventHeader hor">
              <div className="left ver flex">
                <div className="eventTitle">
                  <h1>{comingEvent.title}</h1>
                </div>
                <div className="eventInfo">
                  {comingEvent.date && (
                    <div className="infoItemD">
                      <p>
                        <FaCalendar className="icon" />
                        {comingEvent.date}
                      </p>
                    </div>
                  )}
                  {comingEvent.time && (
                    <div className="infoItemD">
                      <p>
                        <FaClock className="icon" />
                        {comingEvent.time}
                      </p>
                    </div>
                  )}
                  {comingEvent.venue && (
                    <div className="infoItemD">
                      <p>
                        <FaMapMarker className="icon" />
                        {comingEvent.venue}
                      </p>
                    </div>
                  )}
                </div>
                <div className="buyTickets">
                  <Link
                    to={"/donate"}
                    className="btnPrimary bkgRed"
                    style={{ marginTop: "20px" }}
                  >
                    Donate
                  </Link>
                </div>
              </div>
              <div className="right ver flex">
                <div className="imgWrapper">
                  <img src={comingEvent.image} alt={comingEvent.title} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {comingEvent.details && (
        <section className="eventDetail">
          <div className="container grayBackground">
            <div className="detailWrapper">
              <p>{comingEvent.details}</p>
            </div>
          </div>
        </section>
      )}
      <section className="eventsSection" style={{ marginBottom: "4rem" }}>
        <div className="container">
          <UpcomingEvents />
        </div>
      </section>
    </>
  );
};

export default ComingEvents;
