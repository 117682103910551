import React from "react";
import { Helmet } from "react-helmet";

const ContactUsMetadata = () => {
  const baseUrl = "https://hestiasfoundation.org"; // Replace with your actual base URL
  const contactUrl = `${baseUrl}/contact-us`;

  const metadata = {
    title: "Contact Us | Hestias Foundation",
    description:
      "Get in touch with Hestias Foundation. We're here to answer your questions about our educational initiatives for underprivileged children. Reach out to us for support, partnerships, or to learn how you can contribute.",
    keywords:
      "contact Hestias Foundation, get in touch, support education, charity contact, educational initiatives, underprivileged children",
    og: {
      title: "Contact Hestias Foundation - We're Here to Help",
      description:
        "Have questions about our work in education for underprivileged children? Contact Hestias Foundation today. Your support can make a difference.",
      image: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
      url: contactUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Reach Out to Hestias Foundation",
      description:
        "Contact us to learn more about our educational initiatives and how you can support underprivileged children's education.",
      image: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "ContactPage",
      name: "Contact Hestias Foundation",
      description:
        "Contact page for Hestias Foundation, an NGO dedicated to providing education to underprivileged children.",
      url: contactUrl,
      mainEntity: {
        "@type": "Organization",
        name: "Hestias Foundation",
        telephone: "+1-xxx-xxx-xxxx", // Replace with actual phone number
        email: "info@hestiasfoundation.org", // Replace with actual email
        address: {
          "@type": "PostalAddress",
          streetAddress: "123 Education Street", // Replace with actual address
          addressLocality: "City",
          addressRegion: "State",
          postalCode: "12345",
          addressCountry: "Country",
        },
        contactPoint: {
          "@type": "ContactPoint",
          contactType: "customer support",
          telephone: "+1-xxx-xxx-xxxx", // Replace with actual phone number
          email: "support@hestiasfoundation.org", 
        },
      },
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default ContactUsMetadata;
