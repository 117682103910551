import React from "react";
import "./volunteer.css";
import VolunteerForm from "../../components/forms/VolunteerForm";

const Volunteer = () => {
  return (
    <>
      <section className="membershipHeader pageSectionHeader">
        <div className="container">
          <div className="heroContent">
            <div className="txtContent">
              <h1>Become a volunteer</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="formHolderSection">
        <div className="container">
          <VolunteerForm />
        </div>
      </section>
    </>
  );
};

export default Volunteer;
