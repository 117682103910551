// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/events/5.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aboutUsHeader {
  background-color: var(--red);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.valuesSection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.valuesSection .container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.valuesSection h2 {
  font-family: days one;
  text-transform: capitalize;
}

.statementsWrapper {
  margin-top: 2rem;
  color: var(--color-white);
}

.statementsWrapper .hor {
  gap: 0;
}
.statementsWrapper .ver {
  min-height: 20rem;
  padding: 2rem;
  align-items: flex-start;
  /* border: solid blue 2px; */
}

.statementsWrapper .txtContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* border: solid blue 2px; */
}

.statementsWrapper .icon {
  font-size: 3rem;
}
.statementsWrapper h3 {
  font-family: days one;
  text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./src/pages/about-us/about-us.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,yDAAuD;AACzD;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,MAAM;AACR;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,4BAA4B;AAC9B;;AAEA;EACE,eAAe;AACjB;AACA;EACE,qBAAqB;EACrB,0BAA0B;AAC5B","sourcesContent":[".aboutUsHeader {\n  background-color: var(--red);\n  background-image: url(../../assets/images/events/5.jpg);\n}\n.valuesSection {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.valuesSection .container {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  justify-content: center;\n  align-items: center;\n}\n\n.valuesSection h2 {\n  font-family: days one;\n  text-transform: capitalize;\n}\n\n.statementsWrapper {\n  margin-top: 2rem;\n  color: var(--color-white);\n}\n\n.statementsWrapper .hor {\n  gap: 0;\n}\n.statementsWrapper .ver {\n  min-height: 20rem;\n  padding: 2rem;\n  align-items: flex-start;\n  /* border: solid blue 2px; */\n}\n\n.statementsWrapper .txtContent {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  /* border: solid blue 2px; */\n}\n\n.statementsWrapper .icon {\n  font-size: 3rem;\n}\n.statementsWrapper h3 {\n  font-family: days one;\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
