import React from "react";
import "./donate.css";

import DonateMetadata from "../../components/generateMetadata/DonateMetadata";

const Donate = () => {
  return (
    <>
      <DonateMetadata />
      <section className="donateHeader pageSectionHeader">
        <div className="container">
          <div className="heroContent">
            <div className="txtContent">
              <h1>Donate</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Donate;
