// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFoundSection {
    /* background-color: var(--color-white); */
    min-height: 80vh;
    padding-top: 8rem;
    padding-bottom: 4rem;
    position: relative;
}

.notFoundSection .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}

.notFoundSection .container .notFoundWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notFoundSection .container .notFoundWrapper h1 {
    font-style: normal;
    font-weight: 200;
    font-size: 84px;
    text-transform: capitalize;
    line-height: 4.5rem;
    color: var(--green-color);
}

.notFoundSection .container .notFoundWrapper h3 {
    font-style: normal;
    font-weight: 300;
    text-transform: capitalize;
    margin-top: 15px;
}

.notFoundSection .container .notFoundWrapper p {
    width: 100%;
}

.notFoundSection .container .notFoundWrapper p a {
    color: var(--green-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/not_found/not_found.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,0BAA0B;IAC1B,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".notFoundSection {\n    /* background-color: var(--color-white); */\n    min-height: 80vh;\n    padding-top: 8rem;\n    padding-bottom: 4rem;\n    position: relative;\n}\n\n.notFoundSection .container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 50vh;\n}\n\n.notFoundSection .container .notFoundWrapper {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.notFoundSection .container .notFoundWrapper h1 {\n    font-style: normal;\n    font-weight: 200;\n    font-size: 84px;\n    text-transform: capitalize;\n    line-height: 4.5rem;\n    color: var(--green-color);\n}\n\n.notFoundSection .container .notFoundWrapper h3 {\n    font-style: normal;\n    font-weight: 300;\n    text-transform: capitalize;\n    margin-top: 15px;\n}\n\n.notFoundSection .container .notFoundWrapper p {\n    width: 100%;\n}\n\n.notFoundSection .container .notFoundWrapper p a {\n    color: var(--green-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
