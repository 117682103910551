import React from "react";
import { Helmet } from "react-helmet";

const HomePageMetadata = () => {
  const baseUrl = "https://hestiasfoundation.org";
  const homeUrl = baseUrl;

  const metadata = {
    title: "Hestias Foundation - Empowering Children Through Education",
    description:
      "Hestias Foundation is dedicated to providing education to less privileged children. Join us in empowering futures, breaking cycles of poverty, and creating lasting change through knowledge.",
    keywords:
      "Hestias Foundation, education, underprivileged children, charity, donate, empowerment, knowledge, poverty alleviation",
    og: {
      title: "Hestias Foundation - Give the Gift of Education",
      description:
        "Empower a child's future by supporting their educational journey. Join Hestias Foundation in making education a reality for every child.",
      image: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
      url: homeUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Hestias Foundation - Changing Lives with Knowledge",
      description:
        "Support Hestias Foundation in providing education to less privileged children. Together, we can transform futures and build brighter communities.",
      image: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "NGO",
      name: "Hestias Foundation",
      url: homeUrl,
      logo: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
      description:
        "Hestias Foundation is dedicated to ensuring less privileged children have access to education, fighting against barriers and providing support for a better future.",
      foundingDate: "2024", // Replace with actual founding year
      address: {
        "@type": "PostalAddress",
        streetAddress: "123 Education Street", // Replace with actual address
        addressLocality: "City",
        addressRegion: "State",
        postalCode: "12345",
        addressCountry: "Country",
      },
      sameAs: [
        "https://www.facebook.com/hestiasfoundation",
        "https://tiktok.com/hestiasfoundation",
        "https://www.instagram.com/hestiasfoundation",
        "https://www.youtube.com/@HestiasFoundation",
      ],
      memberOf: {
        "@type": "Organization",
        name: "NGO", // Replace with actual affiliations if any
      },
      actionableFeedbackPolicy: `${baseUrl}/feedback-policy`, // Replace with actual URL if available
      slogan: "Empowering futures through education",
      areaServed: {
        "@type": "Country",
        name: "Ghana", // Replace with actual area served
      },
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default HomePageMetadata;
