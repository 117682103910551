import React, { useState, useRef, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification from "../../components/notification";
import { SERVER_URL } from "../../utils/constant";
import "./forms.css";

const VolunteerForm = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("success");
  const [previewFiles, setPreviewFiles] = useState([]);
  const [isDragActive, setIsDragActive] = useState(false);
  const fileInputRef = useRef(null);

  // Gender options
  const genderOptions = [
    { value: "", label: "Select Gender" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
    { value: "prefer_not_to_say", label: "Prefer Not to Say" },
  ];

  // Education status options
  const educationOptions = [
    { value: "", label: "Select Education Level" },
    { value: "high_school", label: "High School" },
    { value: "undergraduate", label: "Undergraduate" },
    { value: "graduate", label: "Graduate" },
    { value: "postgraduate", label: "Postgraduate" },
    { value: "other", label: "Other" },
  ];

  const validationSchema = Yup.object({
    fname: Yup.string().required("Required"),
    lname: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    age: Yup.number()
      .positive("Age must be a positive number")
      .required("Required"),
    gender: Yup.string()
      .oneOf(
        genderOptions
          .map((option) => option.value)
          .filter((value) => value !== ""),
        "Please select a valid gender"
      )
      .required("Gender is required"),
    education_status: Yup.string()
      .oneOf(
        educationOptions
          .map((option) => option.value)
          .filter((value) => value !== ""),
        "Please select a valid education level"
      )
      .required("Education level is required"),
    address: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    self_description: Yup.string().required("Required"),
    documents: Yup.array()
      .of(
        Yup.mixed()
          .test("fileSize", "File is too large", (value) => {
            return value && value.size <= 5 * 1024 * 1024; // 5MB limit
          })
          .test("fileType", "Only image files are allowed", (value) => {
            const supportedTypes = [
              "image/jpeg",
              "image/png",
              "image/gif",
              "image/webp",
            ];
            return value && supportedTypes.includes(value.type);
          })
      )
      .max(1, "Only one document allowed")
      .required("Please upload a photograph"),
  });

  const processFiles = useCallback((files, setFieldValue) => {
    // Filter out files that don't meet the validation criteria
    const supportedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
    ];
    const validFiles = Array.from(files).filter(
      (file) =>
        file.size <= 5 * 1024 * 1024 && supportedTypes.includes(file.type)
    );

    // Take only the first file
    const updatedFiles = validFiles.slice(0, 1);

    // Update form value and preview
    setFieldValue("documents", updatedFiles);
    setPreviewFiles(updatedFiles);
  }, []);

  const handleFileChange = (event, setFieldValue) => {
    processFiles(event.target.files, setFieldValue);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(false);
  };

  const handleDrop = (event, setFieldValue) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(false);

    if (event.dataTransfer.files) {
      processFiles(event.dataTransfer.files, setFieldValue);
    }
  };

  const removeFile = (setFieldValue) => {
    setFieldValue("documents", []);
    setPreviewFiles([]);
  };

  const getFileIcon = (file) => {
    const fileType = file.type;
    if (fileType.startsWith("image/")) return "📷";
    return "📁";
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === "documents") {
        // Append the single document to the form data
        if (values[key].length > 0) {
          formData.append("documents", values[key][0]);
        }
      } else {
        formData.append(key, values[key]);
      }
    });

    fetch(`${SERVER_URL}/volunteer-application`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setNotificationMessage(data.message);
        setNotificationType(data.success ? "success" : "error");
        setShowNotification(true);
        setSubmitting(false);

        if (data.success) {
          setTimeout(() => {
            setShowNotification(false);
          }, 3000);
          // window.location.assign("/");
          resetForm(); // Reset the form after successful submission
          setPreviewFiles([]); // Clear preview files
        }
      })
      .catch((error) => {
        setNotificationMessage("An error occurred. Please try again later.");
        setNotificationType("error");
        setShowNotification(true);
        setSubmitting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          fname: "",
          lname: "",
          email: "",
          age: "",
          gender: "",
          education_status: "",
          address: "",
          phone: "",
          self_description: "",
          documents: [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <div className="formWrapper">
              <br />
              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="fname">First Name</label>
                  <Field type="text" name="fname" />
                  <ErrorMessage
                    name="fname"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="lname">Last Name</label>
                  <Field type="text" name="lname" />
                  <ErrorMessage
                    name="lname"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="email">Email</label>
                  <Field type="email" name="email" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="age">Age</label>
                  <Field type="number" name="age" />
                  <ErrorMessage
                    name="age"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="gender">Gender</label>
                  <Field as="select" name="gender">
                    {genderOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="education_status">Level of Education</label>
                  <Field as="select" name="education_status">
                    {educationOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="education_status"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="address">Address</label>
                  <Field type="text" name="address" />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="phone">Phone Number</label>
                  <Field type="text" name="phone" />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="self_description">
                    A Brief Description of Yourself
                  </label>
                  <Field as="textarea" name="self_description" />
                  <ErrorMessage
                    name="self_description"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="form-field">
                <label htmlFor="documents">
                  Upload a Photograph of Yourself
                </label>
                <span className="file-constraints">
                  <i>(Supported files are jpeg, png, gif, webp)</i>
                </span>
                <div
                  className={`file-drop-zone ${
                    isDragActive ? "drag-active" : ""
                  }`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, setFieldValue)}
                >
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChange={(e) => handleFileChange(e, setFieldValue)}
                    className="hidden-input"
                    accept="image/jpeg,image/png,image/gif,image/webp"
                  />
                  {previewFiles.length === 0 ? (
                    <div className="file-drop-content">
                      <p>Drag and drop image here or</p>
                      <button
                        type="button"
                        className="file-choose-btn"
                        onClick={() => fileInputRef.current.click()}
                      >
                        Choose File
                      </button>
                      <p className="file-constraints">Max 1 file, 5MB</p>
                    </div>
                  ) : (
                    <div className="file-preview">
                      {previewFiles.map((file, index) => (
                        <div key={index} className="file-item">
                          <span>
                            {getFileIcon(file)} {file.name}
                          </span>
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() => removeFile(setFieldValue)}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <ErrorMessage
                  name="documents"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="buttonWrapper">
                <button
                  type="submit"
                  className="form-field-submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending..." : "Send"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {showNotification && (
        <Notification
          message={notificationMessage}
          notificationType={notificationType}
          onClose={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default VolunteerForm;
