import React from "react";
import "./apply.css";
import { applyContent } from "../../constants/data";
import ApplyForm from "../../components/forms/ApplyForm";

const Apply = () => {
  return (
    <>
      <section className="aboutUsHeader pageSectionHeader">
        <div className="container">
          <div className="heroContent">
            <div className="txtContent">
              <h1>{applyContent.heroText}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="eligibility">
        <div className="container">
          <div className="welcomeStatement">
            <article>{applyContent.welcomeStatement}</article>
          </div>

          <div className="criteria">
            <div className="txtContent">
              <div className="txt">
                <h3 className="colorTeal">
                  {applyContent.eligibilityCliteria.title}
                </h3>
                <h5>{applyContent.eligibilityCliteria.subTitle}</h5>
                <article className="eligibility-criteria">
                  <ul className="eligibility-list">
                    {applyContent.eligibilityCliteria.cliteriaList.map(
                      (criterion, index) => (
                        <li key={index} className="eligibility-item">
                          <span className="eligibility-bullet"></span>
                          <span className="eligibility-text">{criterion}</span>
                        </li>
                      )
                    )}
                  </ul>
                </article>
              </div>
            </div>
          </div>
          <ApplyForm />
        </div>
      </section>
    </>
  );
};

export default Apply;
