import React, { useState, useEffect } from "react";
import "./member-group.css";

const MemberGroup = ({ teamMembers }) => {
  const [expandedMember, setExpandedMember] = useState(null);

  const toggleBio = (index) => {
    setExpandedMember(expandedMember === index ? null : index);
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const memberId = parseInt(hash.replace("#member-", ""));
      const element = document.getElementById(`member-${memberId}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setExpandedMember(memberId);
      }
    }
  }, []);

  return (
    <section id="member-group" className="member-group">
      <div className="header">
        <h2>Our Amazing Team </h2>
      </div>
      <div className="container">
        {teamMembers.map((member, index) => (
          <div
            id={`member-${index}`}
            className={`member-profile ${
              expandedMember === index ? "expanded" : ""
            }`}
            key={index}
          >
            <div className="profile-avatar">
              <img src={member.image} alt={member.name} />
            </div>
            <div className="member-content">
              <div className="top">
                <h3>{member.name}</h3>
                <span>{member.role}</span>
              </div>
              <article>
                {expandedMember === index
                  ? member.info
                  : `${member.info.substring(0, 250)}...`}
              </article>
              {member.info.length > 250 && (
                <button className="btn" onClick={() => toggleBio(index)}>
                  {expandedMember === index ? "Show Less" : "Read More"}
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MemberGroup;
