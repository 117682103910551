import { lazy, useState } from "react";
import { Outlet } from "react-router-dom";

const Navbar = lazy(() => import("../components/Navbar"));
const Footer = lazy(() => import("../components/Footer"));
const DonateModal = lazy(() => import("../components/modal/DonateModal"));

function Layout() {
  const [showModal, setShowModal] = useState(false);

  const openDonateModal = () => {
    setShowModal(true);
  };

  const closeDonateModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Navbar openDonateModal={openDonateModal} />
      <Outlet context={{ openDonateModal }} />
      <Footer />
      <DonateModal
        showDonateModal={showModal}
        closeDonateModal={closeDonateModal}
      />
    </div>
  );
}

export default Layout;
