import React, { useState, useRef, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification from "../../components/notification";
import { SERVER_URL } from "../../utils/constant";
import "./forms.css";

const ApplyForm = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("success");
  const [previewFiles, setPreviewFiles] = useState([]);
  const [isDragActive, setIsDragActive] = useState(false);
  const fileInputRef = useRef(null);

  // Gender options
  const genderOptions = [
    { value: "", label: "Select Gender" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
    { value: "prefer_not_to_say", label: "Prefer Not to Say" },
  ];

  const validationSchema = Yup.object({
    fname: Yup.string().required("Required"),
    lname: Yup.string().required("Required"),
    age: Yup.string().required("Required"),
    gender: Yup.string()
      .oneOf(
        genderOptions
          .map((option) => option.value)
          .filter((value) => value !== ""),
        "Please select a valid gender"
      )
      .required("Gender is required"),
    education_status: Yup.string().required("Required"),
    gfname: Yup.string().required("Required"),
    glname: Yup.string().required("Required"),
    contact: Yup.string().required("Required"),
    relationship: Yup.string().required("Required"),
    reasons_for_application: Yup.string().required("Required"),
    location: Yup.string().required("Required"),
    glocation: Yup.string().required("Required"),
    fullname: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    fo_phone: Yup.string().required("Required"),
    fo_address: Yup.string().required("Required"),
    documents: Yup.array()
      .of(
        Yup.mixed()
          .test("fileSize", "File is too large", (value) => {
            return value && value.size <= 5 * 1024 * 1024; // 5MB limit
          })
          .test("fileType", "Unsupported file type", (value) => {
            const supportedTypes = [
              "image/jpeg",
              "image/png",
              "image/gif",
              "application/pdf",
              "image/webp",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ];
            return value && supportedTypes.includes(value.type);
          })
      )
      .max(5, "Maximum 5 documents allowed")
      .required("Please upload at least one document"),
  });

  const processFiles = useCallback(
    (files, setFieldValue) => {
      // Filter out files that don't meet the validation criteria
      const supportedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "application/pdf",
        "image/webp",
      ];
      const validFiles = Array.from(files).filter(
        (file) =>
          file.size <= 5 * 1024 * 1024 && supportedTypes.includes(file.type)
      );

      // Combine with existing files, ensuring no duplicates and max 5 files
      const updatedFiles = [...(previewFiles || []), ...validFiles].slice(0, 5);

      // Update form value and preview
      setFieldValue("documents", updatedFiles);
      setPreviewFiles(updatedFiles);
    },
    [previewFiles]
  );

  const handleFileChange = (event, setFieldValue) => {
    processFiles(event.target.files, setFieldValue);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(false);
  };

  const handleDrop = (event, setFieldValue) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(false);

    if (event.dataTransfer.files) {
      processFiles(event.dataTransfer.files, setFieldValue);
    }
  };

  const removeFile = (indexToRemove, setFieldValue) => {
    const updatedFiles = previewFiles.filter(
      (_, index) => index !== indexToRemove
    );
    setFieldValue("documents", updatedFiles);
    setPreviewFiles(updatedFiles);
  };

  const getFileIcon = (file) => {
    const fileType = file.type;
    if (fileType.startsWith("image/")) return "📷";
    if (fileType === "application/pdf") return "📄";
    return "📁";
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === "documents") {
        // Append each document to the form data
        values[key].forEach((file) => {
          formData.append("documents", file);
        });
      } else {
        formData.append(key, values[key]);
      }
    });
    // fetch from api
    fetch(`${SERVER_URL}/sponsorship-application`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setNotificationMessage(data.message);
        setNotificationType(data.success ? "success" : "error");
        setShowNotification(true);
        setSubmitting(false);

        if (data.success) {
          setTimeout(() => {
            setShowNotification(false);
          }, 3000);
          // window.location.assign("/");
          resetForm(); // Reset the form after successful submission
          setPreviewFiles([]); // Clear preview files
        }
      })
      .catch((error) => {
        setNotificationMessage("An error occurred. Please try again later.");
        setNotificationType("error");
        setShowNotification(true);
        setSubmitting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          fname: "",
          lname: "",
          age: "",
          gender: "",
          education_status: "",
          gfname: "",
          glname: "",
          contact: "",
          relationship: "",
          reasons_for_application: "",
          location: "",
          glocation: "",
          fullname: "",
          email: "",
          fo_phone: "",
          fo_address: "",
          documents: [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <div className="formWrapper">
              <div className="txtHeader">
                <h2 className="colorTeal">Applicant Information</h2> <br />
              </div>
              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="fname">First Name</label>
                  <Field type="text" name="fname" />
                  <ErrorMessage
                    name="fname"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="lname">Last Name</label>
                  <Field type="text" name="lname" />
                  <ErrorMessage
                    name="lname"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="age">Age</label>
                  <Field type="number" name="age" />
                  <ErrorMessage
                    name="age"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="gender">Gender</label>
                  <Field as="select" name="gender">
                    {genderOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="location">Location</label>
                  <Field type="text" name="location" />
                  <ErrorMessage
                    name="location"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="education_status">
                    Current education status
                  </label>
                  <Field type="text" name="education_status" />
                  <ErrorMessage
                    name="education_status"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <br />
              <div className="txtHeader">
                <h2 className="colorTeal">Guardian Information</h2> <br />
              </div>

              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="gfname">First Name</label>
                  <Field type="text" name="gfname" />
                  <ErrorMessage
                    name="gfname"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="glname">Last Name</label>
                  <Field type="text" name="glname" />
                  <ErrorMessage
                    name="glname"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="contact">Contact</label>
                  <Field type="text" name="contact" />
                  <ErrorMessage
                    name="contact"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="relationship">
                    Relationship with the child
                  </label>
                  <Field type="text" name="relationship" />
                  <ErrorMessage
                    name="relationship"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="glocation">Location</label>
                  <Field type="text" name="glocation" />
                  <ErrorMessage
                    name="glocation"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="reasons_for_application">
                    Reason for Application: Brief explanation of why the
                    scholarship is needed
                  </label>
                  <Field as="textarea" name="reasons_for_application" />
                  <ErrorMessage
                    name="reasons_for_application"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <br />
              <div className="txtHeader">
                <h2 className="colorTeal">Field Officer Information</h2>
                <span className="file-constraints">
                  If you are applying on behalf of someone, this section is for
                  you. If you are a guardian of the applicant, this section is
                  for you as well. All fields here are required.
                </span>
                <br />
                <br />
              </div>

              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="fullname">Fullname</label>
                  <Field type="text" name="fullname" />
                  <ErrorMessage
                    name="fullname"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="email">Email</label>
                  <Field type="text" name="email" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <div className="formCol">
                <div className="form-field">
                  <label htmlFor="fo_phone">Phone Number</label>
                  <Field type="text" name="fo_phone" />
                  <ErrorMessage
                    name="fo_phone"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="fo_address">Address</label>
                  <Field type="text" name="fo_address" />
                  <ErrorMessage
                    name="fo_address"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>

              <br />
              <div className="txtHeader">
                <h2 className="colorTeal">Supporting Documents</h2> <br />
              </div>

              <div className="form-field">
                <label htmlFor="documents">
                  Upload Documents (Pictures of applicant,Terminal Report Cards,
                  or any relevant document)
                </label>
                <span className="file-constraints">
                  <i>(Supported files are jpeg, pdf, gif,webp, and png)</i>
                </span>
                <div
                  className={`file-drop-zone ${
                    isDragActive ? "drag-active" : ""
                  }`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, setFieldValue)}
                >
                  <input
                    ref={fileInputRef}
                    type="file"
                    multiple
                    onChange={(e) => handleFileChange(e, setFieldValue)}
                    className="hidden-input"
                    accept="image/jpeg,image/png,image/gif,application/pdf,image/webp"
                  />
                  {previewFiles.length === 0 ? (
                    <div className="file-drop-content">
                      <p>Drag and drop files here or</p>
                      <button
                        type="button"
                        className="file-choose-btn"
                        onClick={() => fileInputRef.current.click()}
                      >
                        Choose Files
                      </button>
                      <p className="file-constraints">Max 5 files, 5MB each</p>
                    </div>
                  ) : (
                    <div>
                      {previewFiles.length < 5 && (
                        <button
                          type="button"
                          onClick={() => fileInputRef.current.click()}
                          className="add-file-btn"
                        >
                          + Add More Files
                        </button>
                      )}
                    </div>
                  )}
                </div>

                <ErrorMessage
                  name="documents"
                  component="div"
                  className="error-message"
                />
                {previewFiles.length > 0 && (
                  <div className="file-preview">
                    {previewFiles.map((file, index) => (
                      <div key={index} className="file-item">
                        <span>
                          {getFileIcon(file)} {file.name}
                        </span>
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={() => removeFile(index, setFieldValue)}
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="buttonWrapper">
                <button
                  type="submit"
                  className="form-field-submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Sending..." : "Submit your application"}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {showNotification && (
        <Notification
          message={notificationMessage}
          notificationType={notificationType}
          onClose={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default ApplyForm;
