import React from "react";
import { Helmet } from "react-helmet";

const TermsAndConditionsContent = () => {
  const baseUrl = "https://hestiasfoundation.org"; // Replace with your actual base URL
  const termsUrl = `${baseUrl}/terms-and-conditions`;

  const metadata = {
    title: "Terms and Conditions | Hestias Foundation",
    description:
      "Read Hestias Foundation's Terms and Conditions. Understand the agreement between our educational charity and our website users, donors, and beneficiaries.",
    keywords:
      "Hestias Foundation terms and conditions, legal agreement, educational charity terms, donation terms",
    og: {
      title: "Hestias Foundation Terms and Conditions",
      description:
        "Learn about the terms governing your use of Hestias Foundation's website and services in support of education for underprivileged children.",
      url: termsUrl,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: "Hestias Foundation Terms and Conditions",
      description:
        "Terms and Conditions for Hestias Foundation, outlining the legal agreement for using our website and services in our educational charity work.",
      url: termsUrl,
      publisher: {
        "@type": "NGO",
        name: "Hestias Foundation",
        url: baseUrl,
      },
    },
  };

  const termsContent = {
    lastUpdated: "20 September 2024",
    effectiveDate: "1 October 2024",
  };
  const contentHTML = `
      <h1>Terms and Conditions</h1>
      <small>Last Updated: ${termsContent.lastUpdated}</small>
      <small>Effective Date: ${termsContent.effectiveDate}</small>

      <p>Welcome to Hestias Foundation. These Terms and Conditions govern your use of our website and services. By accessing or using our website, you agree to be bound by these Terms. If you disagree with any part of these terms, please do not use our website or services.</p>

      <h4>1. Use of Our Website</h4>
      <p>The content of this website is for general information and use only. It is subject to change without notice. We do not provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose.</p>

      <h4>2. Donations and Contributions</h4>
      <ul>
        <li>All donations are final and non-refundable.</li>
        <li>We reserve the right to use donations as we see fit in pursuit of our charitable mission, unless otherwise specified in writing.</li>
        <li>Tax receipts will be issued for eligible donations as per applicable laws.</li>
      </ul>

      <h4>3. Intellectual Property</h4>
      <p>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p>

      <h4>4. User Conduct</h4>
      <p>You agree to use our website only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the website.</p>

      <h4>5. Links to Other Websites</h4>
      <p>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites.</p>

      <h4>6. Privacy and Data Protection</h4>
      <p>Your use of this website is also governed by our Privacy Policy and Cookie Policy, which are incorporated into these Terms by reference.</p>

      <h4>7. Limitation of Liability</h4>
      <p>Hestias Foundation shall not be liable for any direct, indirect, incidental, consequential or punitive damages arising out of your access to, or use of, the website and services.</p>

      <h4>8. Governing Law</h4>
      <p>These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>

      <h4>9. Changes to These Terms</h4>
      <p>We reserve the right to modify these Terms at any time. We will always post the most current version on our website. By continuing to use the site after changes have been made, you agree to be bound by the revised terms.</p>

      <h4>Contact Us</h4>
      <p>If you have any questions about these Terms, please contact us at:</p>
      <p>Hestias Foundation<br />
      123 Education Street, City, State 12345<br />
      Email: support@hestiasfoundation.org<br />
      Phone: +1 (234) 567-8900</p>
    `;

  return (
    <>
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />

        <meta property="og:title" content={metadata.og.title} />
        <meta property="og:description" content={metadata.og.description} />
        <meta property="og:url" content={metadata.og.url} />

        <script type="application/ld+json">
          {JSON.stringify(metadata.schema)}
        </script>
      </Helmet>
      <section className="policy pt-8">
        <div className="container pt-4">
          <div className="policyTextContent">
            <div dangerouslySetInnerHTML={{ __html: contentHTML }} />
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditionsContent;
