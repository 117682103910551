// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eligibility .welcomeStatement {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.criteria h3 {
  margin-bottom: 01rem;
  font-size: x-large;
}

.criteria h5 {
  margin-bottom: 0.2rem;
  font-size: large;
}

.criteria h5 {
  margin-bottom: 0.2rem;
  font-size: large;
}

.criteria article {
  margin-bottom: 3rem;
}

.eligibility-criteria {
  width: 100%;
}

.eligibility-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.eligibility-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  position: relative;
  padding-left: 25px;
}

.eligibility-bullet {
  position: absolute;
  left: 0;
  top: 8px;
  width: 10px;
  height: 10px;
  background-color: var(--teal);
  border-radius: 50%;
}

.eligibility-text {
  line-height: 1.6;
  color: #333;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/apply/apply.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,eAAe;AACjB","sourcesContent":[".eligibility .welcomeStatement {\n  margin-top: 3rem;\n  margin-bottom: 3rem;\n}\n\n.criteria h3 {\n  margin-bottom: 01rem;\n  font-size: x-large;\n}\n\n.criteria h5 {\n  margin-bottom: 0.2rem;\n  font-size: large;\n}\n\n.criteria h5 {\n  margin-bottom: 0.2rem;\n  font-size: large;\n}\n\n.criteria article {\n  margin-bottom: 3rem;\n}\n\n.eligibility-criteria {\n  width: 100%;\n}\n\n.eligibility-list {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.eligibility-item {\n  display: flex;\n  align-items: flex-start;\n  margin-bottom: 12px;\n  position: relative;\n  padding-left: 25px;\n}\n\n.eligibility-bullet {\n  position: absolute;\n  left: 0;\n  top: 8px;\n  width: 10px;\n  height: 10px;\n  background-color: var(--teal);\n  border-radius: 50%;\n}\n\n.eligibility-text {\n  line-height: 1.6;\n  color: #333;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
