import React, { lazy } from "react";
import "./programs.css";
import { programsContent } from "../../constants/data";
import ProgramsMetadata from "../../components/generateMetadata/ProgramsMetadata";
import { Link } from "react-router-dom";
import ProgressiveImage from "../../components/ProgressiveImage";
import { events } from "../../constants/data";
const Accordion = lazy(() => import("../../components/accordion"));

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength).trim() + "...";
};

function convertToSlug(text) {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
}

const Programs = () => {
  const projectEvents = events.filter((event) => event.project);
  return (
    <>
      <ProgramsMetadata programsContent={programsContent} />
      <section className="programsHeader pageSectionHeader">
        <div className="container">
          <div className="heroContent">
            <div className="txtContent">
              <h1>{programsContent.heroText}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="projects">
        <div className="container">
          <div className="top">
            <div className="txtContent">
              <div className="txt">
                <article>{programsContent.projectSection.textContent}</article>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="eventCards">
              {projectEvents.map((event) => (
                <div className="eventCard" key={event.id}>
                  <div className="imageSection">
                    <div className="eventImageWrapper">
                      <ProgressiveImage
                        lowQualitySrc={event.image.lowQualityImage}
                        highQualitySrc={event.image}
                        alt={event.title}
                        className="eventImage"
                      />
                    </div>
                  </div>
                  <div className="txtSection">
                    <div className="txtContent">
                      <div className="txtHeader">
                        <Link to={`/events/${convertToSlug(event.title)}`}>
                          <h3 className="colorTeal">
                            {truncateText(event.title, 100)}
                          </h3>
                        </Link>
                      </div>
                      <div className="txt">
                        <article>{truncateText(event.details, 350)}</article>
                      </div>
                    </div>
                    <div className="btnWrapper">
                      <Link
                        to={`/events/${convertToSlug(event.title)}`}
                        className="btnCorner"
                      >
                        +
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="programsList ">
        <div className="container">
          <div className="packages">
            {programsContent.accordionData.map((accordion, index) => (
              <Accordion key={index} title={accordion.title}>
                {accordion.content.map((section, sectionIndex) => (
                  <div key={sectionIndex} className="accItem">
                    {section.subtitle && (
                      <h5 className="accBullet">{section.subtitle}</h5>
                    )}
                    <ul className="accList">
                      {section.items.map((item, itemIndex) => (
                        <li key={itemIndex}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </Accordion>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Programs;
