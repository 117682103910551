import React from "react";
import { Helmet } from "react-helmet";

const CookiePolicyContent = () => {
  const baseUrl = "https://hestiasfoundation.org";
  const policyUrl = `${baseUrl}/cookie-policy`;

  const metadata = {
    title: "Cookie Policy | Hestias Foundation",
    description:
      "Learn about Hestias Foundation's use of cookies on our website. Our policy explains what cookies are, how we use them, and how you can control them while supporting our mission of education for underprivileged children.",
    keywords:
      "Hestias Foundation cookie policy, website cookies, educational charity cookies, data privacy",
    og: {
      title: "Hestias Foundation Cookie Policy",
      description:
        "Understand how Hestias Foundation uses cookies to improve your experience on our website while supporting educational initiatives for underprivileged children.",
      url: policyUrl,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: "Hestias Foundation Cookie Policy",
      description:
        "Cookie Policy for Hestias Foundation, explaining our use of cookies in our educational charity work.",
      url: policyUrl,
      publisher: {
        "@type": "NGO",
        name: "Hestias Foundation",
        url: baseUrl,
      },
    },
  };

  const policyContent = {
    lastUpdated: "20 September 2024",
    effectiveDate: "1 October 2024",
  };
  const contentHTML = `
      <h1>Cookie Policy</h1>
      <small>Last Updated: ${policyContent.lastUpdated}</small>
      <small>Effective Date: ${policyContent.effectiveDate}</small>

      <p>At Hestias Foundation, we use cookies and similar technologies to enhance your experience on our website and to support our mission of providing education to underprivileged children. This Cookie Policy explains what cookies are, how we use them, and how you can control them.</p>

      <h4>What are Cookies?</h4>
      <p>Cookies are small text files that are placed on your device when you visit a website. They are widely used to make websites work more efficiently and provide information to the owners of the site.</p>

      <h4>How We Use Cookies:</h4>
      <p>We use cookies for the following purposes:</p>
      <ul>
        <li>Essential Cookies: These are necessary for the website to function properly and cannot be switched off.</li>
        <li>Performance Cookies: These help us understand how visitors interact with our website by collecting and reporting information anonymously.</li>
        <li>Functional Cookies: These enable the website to provide enhanced functionality and personalization.</li>
        <li>Targeting Cookies: These may be set through our site by our advertising partners to build a profile of your interests and show you relevant ads on other sites.</li>
      </ul>

      <h4>Types of Cookies We Use:</h4>
      <ul>
        <li>Session Cookies: These temporary cookies expire when you close your browser.</li>
        <li>Persistent Cookies: These remain on your device until they expire or you delete them.</li>
        <li>First-party Cookies: Set by us to improve your experience on our site.</li>
        <li>Third-party Cookies: Set by our partners for analytics, advertising, or functionality purposes.</li>
      </ul>

      <h4>Managing Your Cookie Preferences:</h4>
      <p>You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. However, if you do this, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.</p>

      <h4>Changes to This Policy:</h4>
      <p>We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page and updating the "Last Updated" date.</p>

      <h4>Contact Us:</h4>
      <p>If you have any questions about this Cookie Policy, please contact us at:</p>
      <p>Hestias Foundation<br />
      123 Education Street, City, State 12345<br />
      Email: privacy@hestiasfoundation.org<br />
      Phone: +1 (234) 567-8900</p>
    `;

  return (
    <>
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />

        <meta property="og:title" content={metadata.og.title} />
        <meta property="og:description" content={metadata.og.description} />
        <meta property="og:url" content={metadata.og.url} />

        <script type="application/ld+json">
          {JSON.stringify(metadata.schema)}
        </script>
      </Helmet>
      <section className="policy pt-8">
        <div className="container pt-4">
          <div className="policyTextContent">
            <div dangerouslySetInnerHTML={{ __html: contentHTML }} />
          </div>
        </div>
      </section>
    </>
  );
};

export default CookiePolicyContent;
