import React, { lazy } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./not_found.css";

const NotFoundPage = () => {
  return (
    <>
      <section className="notFoundSection">
        <div className="container">
          <div className="notFoundWrapper">
            <h1>4😞4</h1>
            <h3>Page Not Found!</h3>
            <p>
              We're sorry, the page you requested could not be found. <br />
              Please go back to the <Link to="/">homepage</Link>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFoundPage;
