// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eventsSection {
  padding-top: 6rem;
}

.eventsSection .container .header {
  margin: 3rem 0;
}

.eventsSection .container .header h1 {
  text-transform: uppercase;
  text-align: center;
  color: var(--dim);
  font-size: 3.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/events/events.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".eventsSection {\n  padding-top: 6rem;\n}\n\n.eventsSection .container .header {\n  margin: 3rem 0;\n}\n\n.eventsSection .container .header h1 {\n  text-transform: uppercase;\n  text-align: center;\n  color: var(--dim);\n  font-size: 3.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
