const membershipPageMetadata = {
  title: "Membership | Hestias Foundation",
  description:
    "Join Hestias Foundation as a member or volunteer. Together, we can make a difference in children's lives, providing educational opportunities and creating a brighter future.",
  keywords:
    "Hestias Foundation, membership, volunteer, children's education, community support, family, transformative movement",
  og: {
    title: "Join Hestias Foundation - Empower Children's Futures",
    description:
      "Become a member or volunteer with Hestias Foundation. Help us reach more children, expand our programs, and create greater impact in education.",
    image:
      "https://hestiasfoundation.org/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png",
    url: "https://hestiasfoundation.org/membership",
  },
  twitter: {
    card: "summary_large_image",
    title: "Hestias Foundation Membership - Make a Difference",
    description:
      "Join our family or volunteer to support children's education. Together, we can break down barriers and ensure brighter futures.",
    image:
      "https://hestiasfoundation.org/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png",
  },
  schema: {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Hestias Foundation",
    url: "https://hestiasfoundation.org",
    logo: "https://hestiasfoundation.org/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png",
    sameAs: [
      "https://www.facebook.com/hestiasfoundation",
      "https://tiktok.com/hestiasfoundation",
      "https://www.instagram.com/hestiasfoundation",
      "https://www.youtube.com/@HestiasFoundation",
    ],
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+1-xxx-xxx-xxxx",
      contactType: "customer support",
    },
  },
};

export default membershipPageMetadata;
