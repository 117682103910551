import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicyContent = () => {
  const baseUrl = "https://hestiasfoundation.org";
  const policyUrl = `${baseUrl}/privacy-policy`;

  const metadata = {
    title: "Privacy Policy | Hestias Foundation",
    description:
      "Learn about Hestias Foundation's commitment to protecting your privacy. Our policy outlines how we collect, use, and safeguard your personal information in our mission to provide education to underprivileged children.",
    keywords:
      "Hestias Foundation privacy policy, data protection, personal information, educational charity privacy",
    og: {
      title: "Hestias Foundation Privacy Policy",
      description:
        "Read our privacy policy to understand how Hestias Foundation protects your personal information while supporting educational initiatives for underprivileged children.",
      url: policyUrl,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "WebPage",
      name: "Hestias Foundation Privacy Policy",
      description:
        "Privacy Policy for Hestias Foundation, outlining our practices for handling personal information in our educational charity work.",
      url: policyUrl,
      publisher: {
        "@type": "NGO",
        name: "Hestias Foundation",
        url: baseUrl,
      },
    },
  };

  const policyContent = {
    lastUpdated: "20 September 2024",
    effectiveDate: "1 October 2024",
  };
  const contentHTML = `
      <h1>Privacy Policy</h1>
      <small>Last Updated: ${policyContent.lastUpdated}</small>
      <small>Effective Date: ${policyContent.effectiveDate}</small>

      <p>At Hestias Foundation, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices concerning the collection, use, and protection of data in our mission to provide education to underprivileged children.</p>

      <h4>1. Information We Collect</h4>
      <ul>
        <li>Personal Information: We may collect names, contact details, and payment information from donors, volunteers, and program participants.</li>
        <li>Usage Data: We gather anonymous data about website visits and interactions to improve our online services.</li>
        <li>Program-Related Information: For children and families in our programs, we collect necessary educational and socio-economic data to provide appropriate support.</li>
      </ul>

      <h4>2. How We Use Your Information</h4>
      <p>We use the collected information to:</p>
      <ul>
        <li>Provide and improve our educational programs and services</li>
        <li>Process donations and communicate with supporters</li>
        <li>Comply with legal obligations and protect our rights</li>
        <li>Analyze and enhance our website and outreach efforts</li>
      </ul>

      <h4>3. Data Protection and Security</h4>
      <p>Hestias Foundation implements robust security measures to protect your personal information from unauthorized access, alteration, or disclosure. We regularly review and update our security practices to ensure the highest level of data protection.</p>

      <h4>4. Sharing of Information</h4>
      <p>We do not sell or rent personal information to third parties. We may share data with trusted partners who assist us in operating our website, conducting our business, or servicing you, as long as they agree to keep this information confidential.</p>

      <h4>5. Your Rights and Choices</h4>
      <p>You have the right to access, correct, or delete your personal information. You may also opt-out of certain communications or data collection practices. To exercise these rights, please contact us using the information provided below.</p>

      <h4>6. Children's Privacy</h4>
      <p>We are committed to protecting the privacy of children. We collect only necessary information about children in our programs with parental or guardian consent and in compliance with applicable laws.</p>

      <h4>7. Changes to This Policy</h4>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.</p>

      <h4>8. Contact Us</h4>
      <p>If you have any questions about this Privacy Policy, please contact us at:</p>
      <p>Hestias Foundation<br />
      123 Education Street, City, State 12345<br />
      Email: privacy@hestiasfoundation.org<br />
      Phone: +1 (234) 567-8900</p>
    `;

  return (
    <>
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />

        <meta property="og:title" content={metadata.og.title} />
        <meta property="og:description" content={metadata.og.description} />
        <meta property="og:url" content={metadata.og.url} />

        <script type="application/ld+json">
          {JSON.stringify(metadata.schema)}
        </script>
      </Helmet>
      <section className="policy pt-8">
        <div className="container pt-4">
          <div className="policyTextContent">
            <div dangerouslySetInnerHTML={{ __html: contentHTML }} />
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicyContent;
