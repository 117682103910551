import React from "react";
import { Helmet } from "react-helmet";

function convertToSlug(text) {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
}

const generateEventMetadata = (event) => {
  if (!event) return null;

  const baseUrl = "https://hestiasfoundation.org";
  const eventUrl = `${baseUrl}/events/${convertToSlug(event.title)}`;

  return {
    title: `${event.title} | Hestias Foundation`,
    description: event.details.slice(0, 160), // Truncate to a reasonable length for meta description
    keywords: `${event.title}, Hestias Foundation, event`,
    og: {
      title: event.title,
      description: event.details.slice(0, 200),
      image: event.image,
      url: eventUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: event.title,
      description: event.details.slice(0, 200),
      image: event.image,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "Event",
      name: event.title,
      description: event.details,
      image: event.image,
      url: eventUrl,
      organizer: {
        "@type": "Organization",
        name: "Hestias Foundation",
        url: baseUrl,
      },
      // Add more fields like startDate, endDate, location if available in your event data
    },
  };
};

const EventDetailHelmet = ({ event }) => {
  const metadata = generateEventMetadata(event);

  if (!metadata) return null;

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default EventDetailHelmet;
