import React from "react";
import { Link } from "react-router-dom";
import ProgressiveImage from "../../components/ProgressiveImage";
import { events } from "../../constants/data";
import "./featured.css";

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength).trim() + "...";
};

function convertToSlug(text) {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
}

const FeaturedCampaigns = ({ homeContent }) => {
  const featuredEvents = events.filter((event) => event.featured);

  return (
    <section className="featuredCampaigns pageSection bkgLight">
      <div className="container">
        <div className="top">
          <div className="txtContent">
            <div className="txtHeader">
              <h2>{homeContent.featuredCampaignSection.header}</h2>
            </div>
            <div className="txt">
              <article>
                {homeContent.featuredCampaignSection.textContent}
              </article>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="eventCards">
            {featuredEvents.map((event) => (
              <div className="eventCard" key={event.id}>
                <div className="imageSection">
                  <div className="eventImageWrapper">
                    <ProgressiveImage
                      lowQualitySrc={event.image.lowQualityImage}
                      highQualitySrc={event.image}
                      alt={event.title}
                      className="eventImage"
                    />
                  </div>
                </div>
                <div className="txtSection">
                  <div className="txtContent">
                    <div className="txtHeader">
                      <Link to={`/events/${convertToSlug(event.title)}`}>
                        <h3 className="colorTeal">
                          {truncateText(event.title, 100)}
                        </h3>
                      </Link>
                    </div>
                    <div className="txt">
                      <article>{truncateText(event.details, 100)}</article>
                    </div>
                  </div>
                  <div className="btnWrapper">
                    <Link
                      to={`/events/${convertToSlug(event.title)}`}
                      className="btnCorner"
                    >
                      +
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedCampaigns;
