import React from "react";
import { Helmet } from "react-helmet";

const ProgramsMetadata = ({ programsContent }) => {
  const baseUrl = "https://hestiasfoundation.org";
  const programsUrl = `${baseUrl}/programs`;

  const metadata = {
    title: "Our Programs | Hestias Foundation",
    description:
      "Explore Hestias Foundation's educational programs for underprivileged children. From primary education support to skill development initiatives, discover how we're making a difference.",
    keywords: `Hestias Foundation programs, educational initiatives, ${programsContent.accordionData
      .map((program) => program.title)
      .join(", ")}`,
    og: {
      title: "Hestias Foundation Programs - Empowering Through Education",
      description:
        "Learn about Hestias Foundation's range of educational programs designed to support and empower underprivileged children. Join us in making a difference.",
      image: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
      url: programsUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Discover Hestias Foundation's Educational Programs",
      description:
        "From primary education to skill development, explore how Hestias Foundation is changing lives through our diverse educational programs.",
      image: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "ItemList",
      name: "Hestias Foundation Educational Programs",
      description:
        "A comprehensive list of educational programs offered by Hestias Foundation to support underprivileged children.",
      url: programsUrl,
      itemListElement: programsContent.accordionData.map((program, index) => ({
        "@type": "ListItem",
        position: index + 1,
        name: program.title,
        description: program.content[0].items[0], // Assuming the first item is a brief description
        url: `${programsUrl}#${program.title
          .toLowerCase()
          .replace(/\s+/g, "-")}`,
      })),
      provider: {
        "@type": "NGO",
        name: "Hestias Foundation",
        url: baseUrl,
      },
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default ProgramsMetadata;
