import React from "react";
import { Helmet } from "react-helmet";

const ComingEventMetadata = ({ event }) => {
  if (!event) return null;

  const baseUrl = "https://hestiasfoundation.org";
  const eventUrl = `${baseUrl}/upcoming-events/${event.id}`;

  const metadata = {
    title: `${event.title} | Upcoming Event | Hestias Foundation`,
    description: event.details.slice(0, 160), // Truncate to a reasonable length for meta description
    keywords: `${event.title}, Hestias Foundation, upcoming event, ${event.venue}`,
    og: {
      title: event.title,
      description: `Join us on ${event.date} at ${event.time} for ${event.title} at ${event.venue}`,
      image: event.image,
      url: eventUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: `Upcoming: ${event.title}`,
      description: `Join Hestias Foundation on ${event.date} at ${event.time} for ${event.title}`,
      image: event.image,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "Event",
      name: event.title,
      description: event.details,
      image: event.image,
      url: eventUrl,
      startDate: `${event.date}T${event.time}`, // Assuming time is in correct format
      location: {
        "@type": "Place",
        name: event.venue,
        address: {
          "@type": "PostalAddress",
          addressLocality: event.venue, // You might want to split venue into more detailed fields
        },
      },
      organizer: {
        "@type": "Organization",
        name: "Hestias Foundation",
        url: baseUrl,
      },
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default ComingEventMetadata;
