import React from "react";
import { Helmet } from "react-helmet";

const DonateMetadata = () => {
  const baseUrl = "https://hestiasfoundation.org";
  const donateUrl = `${baseUrl}/donate`;

  const metadata = {
    title: "Donate | Hestias Foundation",
    description:
      "Support Hestias Foundation's mission to provide education to underprivileged children. Your donation can make a lasting impact on a child's future.",
    keywords:
      "donate, Hestias Foundation, education, underprivileged children, charity, support, make a difference",
    og: {
      title: "Make a Difference - Donate to Hestias Foundation",
      description:
        "Your donation to Hestias Foundation helps break down barriers to education for underprivileged children. Every contribution counts!",
      image: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
      url: donateUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Support Education - Donate to Hestias Foundation",
      description:
        "Help us empower underprivileged children through education. Your donation to Hestias Foundation can change lives.",
      image: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "DonateAction",
      name: "Donate to Hestias Foundation",
      description:
        "Support Hestias Foundation's mission to provide education to underprivileged children.",
      url: donateUrl,
      actionStatus: "PotentialActionStatus",
      agent: {
        "@type": "NGO",
        name: "Hestias Foundation",
        url: baseUrl,
      },
      recipient: {
        "@type": "NGO",
        name: "Hestias Foundation",
        url: baseUrl,
      },
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default DonateMetadata;
