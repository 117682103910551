import React from "react";
import { Helmet } from "react-helmet";

const AboutUsMetadata = () => {
  const baseUrl = "https://hestiasfoundation.org";
  const aboutUsUrl = `${baseUrl}/about-us`;

  const metadata = {
    title: "About Us | Hestias Foundation",
    description:
      "Hestias Foundation is dedicated to breaking down barriers to education for underprivileged children. Learn about our mission, vision, and core values.",
    keywords:
      "Hestias Foundation, education, underprivileged children, non-profit, mission, vision, core values",
    og: {
      title: "About Hestias Foundation - Empowering Through Education",
      description:
        "Discover how Hestias Foundation is transforming lives by expanding access to quality education and advocating for children's well-being.",
      image: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
      url: aboutUsUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Hestias Foundation: Our Mission and Values",
      description:
        "Learn about Hestias Foundation's commitment to empowering young minds and shaping a brighter future through education.",
      image: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "NGO",
      name: "Hestias Foundation",
      url: baseUrl,
      logo: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
      description:
        "Hestias Foundation is a non-profit organization dedicated to breaking down barriers to education for underprivileged children.",
      foundingDate: "2024", // Replace with actual founding year
      address: {
        "@type": "PostalAddress",
        streetAddress: "123 Education Street", // Replace with actual address
        addressLocality: "City",
        addressRegion: "State",
        postalCode: "12345",
        addressCountry: "Country",
      },
      sameAs: [
        "https://www.facebook.com/hestiasfoundation",
        "https://tiktok.com/hestiasfoundation",
        "https://www.instagram.com/hestiasfoundation",
        "https://www.youtube.com/@HestiasFoundation",
      ],
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default AboutUsMetadata;
