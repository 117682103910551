import React from "react";
import TermsAndConditionsContent from "../../constants/TermsAndConditionsContent";

const TermsAndConditions = () => {
  return (
    <>
      <TermsAndConditionsContent />
    </>
  );
};

export default TermsAndConditions;
