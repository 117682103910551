import React from "react";
import { Helmet } from "react-helmet";

const EventsPageMetadata = () => {
  const baseUrl = "https://hestiasfoundation.org";
  const eventsUrl = `${baseUrl}/events`;

  const metadata = {
    title: "Events | Hestias Foundation",
    description:
      "Explore upcoming and past events organized by Hestias Foundation. Join us in making a difference through our community-focused initiatives and educational programs.",
    keywords:
      "Hestias Foundation, events, community initiatives, educational programs, upcoming events",
    og: {
      title: "Hestias Foundation Events - Join Our Community Initiatives",
      description:
        "Discover and participate in Hestias Foundation's events. From educational workshops to community outreach programs, find opportunities to make a positive impact.",
      image: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
      url: eventsUrl,
    },
    twitter: {
      card: "summary_large_image",
      title: "Hestias Foundation Events Calendar",
      description:
        "Stay updated with Hestias Foundation's event calendar. Find upcoming events and ways to get involved in our community-focused initiatives.",
      image: `${baseUrl}/static/media/hes-logo-PNG-croped.ebfd810052b93f47549e.png`,
    },
    schema: {
      "@context": "https://schema.org",
      "@type": "CollectionPage",
      name: "Hestias Foundation Events",
      description:
        "A listing of past and upcoming events organized by Hestias Foundation, focusing on community initiatives and educational programs.",
      url: eventsUrl,
      publisher: {
        "@type": "Organization",
        name: "Hestias Foundation",
        url: baseUrl,
      },
    },
  };

  return (
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords} />

      <meta property="og:title" content={metadata.og.title} />
      <meta property="og:description" content={metadata.og.description} />
      <meta property="og:image" content={metadata.og.image} />
      <meta property="og:url" content={metadata.og.url} />

      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.image} />

      <script type="application/ld+json">
        {JSON.stringify(metadata.schema)}
      </script>
    </Helmet>
  );
};

export default EventsPageMetadata;
