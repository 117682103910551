// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policy {
  padding-top: 8rem;
}
.policy h1 {
  text-transform: uppercase;
  color: var(--dtn-color-1);
}

.policy h4 {
  text-transform: capitalize;
  padding: 1.5rem 0 0 0;
}

.policy ul {
  padding-top: 0.8rem;
  padding-left: 4%;
}
.policy li {
  list-style-type: decimal;
  font-size: 1.1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/privacy-policy/policy.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,wBAAwB;EACxB,iBAAiB;AACnB","sourcesContent":[".policy {\n  padding-top: 8rem;\n}\n.policy h1 {\n  text-transform: uppercase;\n  color: var(--dtn-color-1);\n}\n\n.policy h4 {\n  text-transform: capitalize;\n  padding: 1.5rem 0 0 0;\n}\n\n.policy ul {\n  padding-top: 0.8rem;\n  padding-left: 4%;\n}\n.policy li {\n  list-style-type: decimal;\n  font-size: 1.1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
